body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.centerCard {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1100px) {
  .centerCard {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .centerCard {
    width: 80%;
  }
}

.card {
  background-color: white;
  padding: 40px 0;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.card-container {
  background-color: white;
  padding: 30px;
  text-align: center;
  margin: 20px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}

.login {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  column-gap: 10px;
  width: 100%;
}
.bgImg {
  background: linear-gradient(0deg, rgb(255 255 255 / 26%), #2e77c800),
    url("assets/BGLogin.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  height: 100%;
  position: relative;
  height: 100vh;
}
.loginForm {
  box-shadow: 0 0 20px rgb(234 234 235 / 50%);
  padding: 10px;
  background-color: #158ee529;
  border: none;
  border-radius: 8px;
  color: black;
  width: 70%;
  margin: 8px;
}
input:focus-visible {
  border-color: #0b1dc9;
  box-shadow: 0 0 0 1px #0b1dc9;
}
.loginForm:focus {
  outline: none;
}
.loginBtn {
  color: #fff;
  background-color: #0b1dc9;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #0b1dc9;
  width: 74%;
  margin: 12px;
  cursor: pointer;
}
.loginBtn:hover {
  color: #0b1dc9;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #0b1dc9;
  width: 74%;
  margin: 12px;
  cursor: pointer;
}
.Btn {
  color: #fff;
  background-color: #0b1dc9;
  padding: 10px 13px;
  border-radius: 4px;
  border: 1px solid #0b1dc9;
  width: auto;
  cursor: pointer;
}
.Btn:hover {
  color: #0b1dc9;
  background-color: #fff;
  padding: 10px 13px;
  border-radius: 4px;
  border: 1px solid #0b1dc9;
  width: auto;
  cursor: pointer;
}
.d-flex {
  display: flex;
}
.justify-content-end {
  justify-content: end;
}

.justify-space-between {
  justify-content: space-between;
}

.buttons {
  justify-content: end;
  display: flex;
  margin: 20px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}
.pb-10 {
  padding-bottom: 10px;
}
.mt-5 {
  margin-top: 5px;
}
.ml-5 {
  margin-left: 10px;
}
.flex-direction-row {
  flex-direction: row;
}
.flex-direction-column {
  flex-direction: column;
}
.viewMobilesField {
  padding-top: 7px;
  padding-bottom: 7px;
  flex-direction: column;
  display: flex;
  margin-right: 30px;
  width: 100%;
}

.viewMobilesField label {
  text-align: left;
  margin-bottom: 5px;
}

.viewMobilesField input {
  /* border: 2px solid #e1dddd; */
  border-radius: 4px;
}
.k-button-solid-base.k-icon-button.k-input-button {
  border-left: none !important;
  background: #ffffff !important;
}

.k-toolbar {
  background-color: #ffffff !important;
  border: none !important;
}
input[type="checkbox"]:checked {
  background-color: #0b1dc9;
  border: none;
}
input[type="checkbox"] {
  margin-right: 3px;
  width: 15px;
  height: 15px;
}
.margin-20 {
  margin: 20px;
}
.margin-28 {
  margin-top: 28px;
}
.k-i-filter {
  cursor: pointer;
  font-size: 24px !important;
}
.fieldWidth {
  width: 282px;
}
.heading {
  font-size: 20px;
  font-weight: 500;
}

.k-grid {
  position: relative !important;
}

.k-grid {
  margin-top: 30px;
}

.k-pager {
  position: absolute !important;
  top: 0 !important;
  border-top: none !important;
  margin-top: -50px;
}
@media (max-width: 567px) {
  .k-pager {
    position: relative !important;
    top: 0 !important;
    border-top: 0 0 1px 1px !important;
    margin-top: 0px;
    border-color: rgba(0, 0, 0, 0.08);
    overflow-x: scroll !important;
  }
}
@media (max-width: 980px) {
  .mblFlexDirectionColumn {
    flex-direction: column;
  }
  .reportsTopCard {
    padding-bottom: 0 !important;
  }
}
.inputGroup {
  border: 1px solid;
  padding: 6px;
  border-radius: 5px;
  border-color: rgb(150 148 148 / 16%);
}
.mr-6 {
  margin-right: 6px !important;
}
.reportsTopCard {
  padding-bottom: 30px;
}
.mt-19 {
  margin-top: 19px;
}
.k-grid-content {
  overflow-y: hidden !important;
}
.k-grid-header {
  padding-inline-end: 0 !important;
}
.k-pager-numbers .k-link {
  color: #424242 !important;
}
.d-none {
  display: none;
}
.radio-pd {
  padding-left: 10px !important;
  padding-left: 10px !important;
}

.pageShow {
  width: 85%;
  position: fixed;
  right: 0px;
  top: 80px;
  height: 100%;
  overflow: auto;
}
.changed {
  color: #fff;
  background-color: #0b1dc9;
  padding: 10px 13px;
  border-radius: 4px;
  border: 1px solid #0b1dc9;
  width: 30%;
  cursor: pointer;
  margin-top: 19px;
}
.changed:hover {
  color: #0b1dc9;
  background-color: #fff;
  padding: 10px 13px;
  border-radius: 4px;
  border: 1px solid #0b1dc9;
  width: 30%;
  cursor: pointer;
}
@media (max-width: 1500px) {
  .pageShow {
    width: 80%;
  }
}
@media (max-width: 600px) {
  .pageShow {
    width: 100%;
  }
  .buttons {
    justify-content: flex-end;
    width: 60%;
    margin: auto;
  }
  .changed {
    width: 100%;
  }
  .changed:hover {
    width: 100%;
  }
}

.sideBar nav {
  background-color: white;
  height: 100%;
}
